
/* style={{width: "20rem", backgroundImage: "linear-gradient(to left bottom, #000000, #303030, #5d5d5d, #8f8f8f, #c4c4c4) !important" }} */

.card {
  /* border: none !important; */
}

.sliderCard {
  /* width: 30rem ; */
  /* height: 25rem !important; */
  /* background-image: linear-gradient(to left bottom, #000000, #262525, #474545, #6b6868, #928d8d) !important; */
  border-radius: 16px !important;
  margin: 0 15px !important;
  padding: 30px 20px;
  display: flex !important;
  flex-direction: column !important;
  /* justify-content: space-between; */
  transition: 0.3s all ease-in-out;
  /* z-index: 999; */
  background: #FFFFFF !important;
  color: #1D1D1F !important;
  align-items: center;
  box-shadow: 2px 4px 12px rgb(0 0 0 / 8%) !important;
}


/* .sliderCard img {
  width: 15rem;
  height: 15rem;
  margin-right: 20px;
} */

.indCard {
  width: 30rem !important;
  height: 25rem !important;
  position: relative !important;
  /* border: 2px solid #333333 !important; */
  border-radius: 20px !important;
  /* margin: 0 15px !important; */
  overflow: hidden;
}

.outerCardDiv {
  box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
  transition: 0.3s all ease-in-out;
  overflow: hidden;
  border-radius: 20px !important;
  margin: 0 15px !important;
  width: fit-content !important;
  padding: 10px;
}

.outerCardDiv h3 {
  text-align: center;
  margin-top: 15px;
  /* font-weight: 600; */
  color: #464646;
}

.outerCardDiv h4 {
  text-align: center;
  margin-top: 15px;
  /* font-weight: 600; */
  color: #464646;
  font-size: 1.1rem;
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (min-width: 760px) {
  .loginCard {
    width: 30rem;
    margin: 0 auto;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    /* padding: 1.5rem; */
  }
}

.mobileCard {
  border: 2px solid #333333 !important;
  margin: 15px !important;
  padding: 2rem;
  height: 20rem;
  position: relative;
}

.mobileCard img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width:760px) {
  .sliderCard {
    width: 18.75rem !important;
    margin: 0 10px !important;
  }

  .mainMobile .col-lg-4 {
    width: 50%;
  }

  .indCard {
    width: 18.75rem !important;
    height: 20rem !important;
  }

  .mobileCard {
    width: 100% !important;
    margin: 15px 0 !important;
    height: 12rem;
  }


  .outerCardDiv {
    padding: 5px;
    padding-top: 10px;
    margin: 0 10px !important;

  }
}

.Prod .slick-slider .slick-list {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.expert .slick-slider .slick-list {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;

}


/* .indCard img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.sliderCard:hover,
.outerCardDiv:hover {
  /* box-shadow: 8px 9px 34px 4px rgb(207 198 198 / 43%) !important; */
  /* transform: translateY(-10%); */
  box-shadow: 2px 4px 16px rgb(0 0 0 / 16%) !important;
  transform: scale3d(1.01, 1.01, 1.01) !important;
}


.slick-slider .slick-list {
  padding: 20px 60px !important;
}

.slick-slide {
  margin: 0 20px !important;
}


.slick-cloned {
  opacity: 0.7 !important;
}

/* Contact */

.reachCard img {
  width: 10rem;
}

.mainCon {
  padding: 3rem;
}

.reachCard .imgDiv {
  width: fit-content;
  padding: 60px 20px;
  border: 1px solid #333333;
  border-radius: 100px;
  margin: 0 auto;
}

.reachCard .imgDiv1 {
  width: fit-content;
  border: 1px solid #333333;
  border-radius: 100px;
  margin: 0 auto;
  padding: 40px;
}

.contactForm .nextui-c-eXOOPO-iLMmYc-isTextarea-true{
  margin: 25px 0 !important;
}

.contactForm .nextui-c-iWjDFM-igxxlKP-css {
  width: 33.33%;
}

.mainMobile .leadCard {
 /* padding: 3rem; */
} 

.videoHeading {
  color: #ffffff;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 10px);
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.nextui-c-BDLTQ .nextui-image{
  filter: grayscale(0.8);
  height: 18rem;
}

.contactHr {
  display: none;
} 

/* .mainHr {
  display: block;
} */

@media screen and (max-width: 480px) {
  .mainCon {
    padding: 3rem 1rem;
  }

.nextui-c-BDLTQ .nextui-image{
height: initial;
}

  .mainHr {
    display: none;
  }

  .contactHr {
    display: block;
  }

  .contactForm .nextui-c-iwmZdB-ifGHEql-css {
    margin: 15px 0 !important;
  }

  .contactForm .nextui-c-iWjDFM-igxxlKP-css {
    width: 100% !important;
  }

  .mainMobile .leadCard h3{
    font-size: 1.1rem;
  }
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(173, 173, 173); 
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 177, 177); 
}
